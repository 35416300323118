//these are foundation-style breakpoint size definitions for when various breakpoints should apply
//these sizes are in units of pixels, and are integers so we can do +/- for "only" and "down" limiters
export const breakpoint_sizes={
	small:0,
	medium:768,
	large:1024,
	xlarge:1280,
	xxlarge:1440,
};

//create a media query that wraps the given css code in the given breakpoint
//arguments:
//	breakpoint_id: the identifier string for the breakpoint to apply, as a two-part 'size limiter' style string
//		e.g. 'small', 'medium', 'large', 'small only'
//		sizes can be small, medium, large, xlarge, and xxlarge
//		limiters can be '' (null), 'only', 'down' or 'up' ('up' is default)
//return:
//	returns a string with a @media query which is appropriate for the given breakpoint definition
//side-effects:
//	none
export function breakpoint(breakpoint_id){
	//standardize to ensure breakpoint_id values are always lower-case
	breakpoint_id=breakpoint_id.toLowerCase();
	
	let brk_id_parts=breakpoint_id.split(' ');
	let size=brk_id_parts[0];
	let limiter=(brk_id_parts.length>1)?(brk_id_parts[1]):'';
	
	//generate the correct media breakpoint string based on the provided arguments
	
	let min_width=null;
	let max_width=null;
	
	//for each configurable breakpoint cutoff size
	let sizes=['small', 'medium', 'large', 'xlarge', 'xxlarge'];
	for(let size_idx=0;size_idx<sizes.length;size_idx++){
		//if this is the breakpoint we're configuring
		if(size===sizes[size_idx]){
			//set the min widht based on the above constants
			min_width=breakpoint_sizes[size];
			max_width=null;
			
			//if we should apply to ONLY this size then set a max as the start of the following breakpoint
			if((limiter==='only') || (limiter==='down')){
				if((size_idx+1)<sizes.length){
					max_width=breakpoint_sizes[sizes[size_idx+1]]-1;
				}
				//for down the next breakpoint should be the max width
				//and there should be no min width
				if(limiter==='down'){
					min_width=null;
				}
			}
			//for an "up" limiter do nothing; we're setting min only and not max
			
			//only one breakpoint gets configured at a time
			break;
		}
	}
	
	//create an accumulator for the media query string
	//and populate it according to max and min values
	let acc='@media all';
	if(min_width!==null){
		acc+=' and (min-width:'+min_width+'px)';
	}
	if(max_width!==null){
		acc+=' and (max-width:'+max_width+'px)';
	}
	
	return acc;
}



