
//get all cookies currently set as a javascript object
export function parse_cookies() {
	let cookies = {};
	if(typeof document !== 'undefined'){
		let cookie_strings = document.cookie.split(";");
		for (let i = 0; i < cookie_strings.length; i++) {
			let cookie_parts = cookie_strings[i].trim().split("=");
			let cookie_name = cookie_parts[0];
			cookies[cookie_name] = cookie_parts[1];
		}
	}
	return cookies;
}

