
export function clearCookies(cookies = null) {
    if (!cookies) {
        cookies = getAllCookies();
    }

    for (let key in cookies) {
        document.cookie = key + '=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
}

export function getAllCookies() {
    return document.cookie.split(';').reduce((ac, str) => Object.assign(
        ac, {[str.split('=')[0].trim()]: str.split('=')[1]}), {});
}